import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { useStore } from "./state.js";
import { getStorage, Logout } from "./helper";
import { setNavigate } from './navigationHelper';
import { IdleTimerProvider } from 'react-idle-timer';

import { Button, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Form from 'react-bootstrap/Form';
import {
  Routes,
  Route,
  BrowserRouter,
  Link,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

import reportWebVitals from './reportWebVitals';

import './styles.css';
import MainLayout from "./layout";
import Login from "./routes/login/index.js";

import Home from "./routes/home";
import Projects from "./routes/projects";
import Backlinks from "./routes/backlinks";
import Users from "./routes/users";
import Roles from "./routes/roles";
import Sources from "./routes/sources";
import ProjectDashboard from "./routes/project-dashboard";


import Project from "./modals/project.js";
import Backlink from "./modals/backlink.js";
import SourceModal from "./modals/source.js";
import User from "./modals/user.js";
import Role from "./modals/role.js";
import ImportBacklinks from "./modals/import.js";
import NiceModal, { ModalDef, useModal } from '@ebay/nice-modal-react';





if (getStorage('token')) {
  useStore.setState({ auth: 1 });
  //setAuth('1');

}
function AuthRoute({ children }) {
  const auth = useStore((state) => state.auth)
  let location = useLocation();
  const org = useStore((state) => state.org)
  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }





  return children;
}


function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}





export default function App() {

  const idleTimerRef = useRef(null);
  const location = useLocation();
  useEffect(() => {


  }, [location]);

  const navigate = useNavigate();

  React.useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);



  return (
    <>

      <Routes>

        <Route path="/" element={<MainLayout />}  >
          <Route path="/" element={<AuthRoute><Home /></AuthRoute>} />
          <Route path="projects" element={<AuthRoute><Projects /></AuthRoute>} />
          <Route path="backlinks" element={<AuthRoute><Backlinks /></AuthRoute>} />
          <Route path="sources" element={<AuthRoute><Sources /></AuthRoute>} />
          <Route path="users" element={<AuthRoute><Users /></AuthRoute>} />
          <Route path="roles" element={<AuthRoute><Roles /></AuthRoute>} />
          <Route path="project/:id" element={<AuthRoute><ProjectDashboard /></AuthRoute>} />



        </Route>

        <Route path="*" element={<NoMatch />} />
        <Route path="/login" element={<Login />} />


      </Routes>
      <ModalDef id="project" component={Project} />
      <ModalDef id="backlink" component={Backlink} />

      <ModalDef id="source" component={SourceModal} />
      <ModalDef id="user" component={User} />
      <ModalDef id="role" component={Role} />
      <ModalDef id="import-backlinks" component={ImportBacklinks} />


    </>
  );
}





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
