import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import { UiSelect, UiInput, OverlayLoader, UiButton, UiDatePicker, UiProjectSelect, BgLoader } from "../ui";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStore } from "../state.js";
import { setStorage, getStorage, rupees } from '../helper.js';
import { AutoComplete } from 'antd';
import { set } from "date-fns";

const Backlink = NiceModal.create(
  ({ id, project }) => {
    const [state, setState] = useState({ count: 0 });
    const modal = useModal();
    const [options, setOptions] = useState([]);


    const schema = yup.object().shape({
      project: yup.mixed().required("Select project"),
      domain: yup.string().required("Enter domain"),
      date: yup.string().required("Enter date"),
      source_url: yup.string().required("Enter source URL"),
      target_url: yup.string().required("Enter target URL"),
      source: yup.string().required("Select source"),
      da: yup.string().required("Enter DA"),
      pa: yup.string().required("Enter PA"),
      bl_status: yup.string().required("Select backlink status"),

      live_link: yup.string().nullable().when('bl_status', {
        is: (value) => 'Live' == value,
        then: (rule) => rule
          .required("This field is required")

      }),
      live_date: yup.string().nullable().when('bl_status', {
        is: (value) => 'Live' == value,
        then: (rule) => rule
          .required("This field is required")
      }),

      lost_date: yup.string().nullable().when('bl_status', {
        is: (value) => 'Loss' == value,
        then: (rule) => rule
          .required("This field is required")

      }),

    }, []);
    const {
      register,
      handleSubmit,
      control,
      unregister,
      setValue,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag(data);
      //reset();
    };
    var SaveTag = (e) => {
      setState({ ...state, loader: true });

      instance({
        method: "post",
        url: "/update_backlink",
        data: e,
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {

            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };


    const mockVal = (str, repeat = 1) => ({
      value: str.repeat(repeat),
    });

    const getPanelValue = (searchText) => {

      instance
        .get("/fetch_domains?&s=" + searchText)
        .then(function (response) {
          setOptions(response.data.items)

        })
        .catch(function (error) { })
        .then(function () { });
    }
    

    const fetchData = () => {
      instance
        .get("/get_backlink?&id=" + id)
        .then(function (response) {
          setState(response.data)
          if (id == 'new') {
            if(project){
              reset({ ...response.data.item, project: parseInt(project) })  
            } else {
            reset({ ...response.data.item })
            }
          } else {
            reset(response.data.item)
          }

        })
        .catch(function (error) { })
        .then(function () { });
    }

const watchProject = watch('project');
    const validateDomain = (domain) => {
      if(watchProject && watchProject.value){
        
      instance
        .get("/validate_domain?&id=" + id + "&domain=" + domain + "&project=" +watchProject.value)
        .then(function (response) {
          
          toast(response.data.error, { type: "error" });

        })
        .catch(function (error) { })
        .then(function () { });
      }
    }



    useEffect(() => {

      fetchData()


    }, []);



    const handleHide = () => {
      modal.hide();
    }


    const watchStatus = watch('bl_status');

    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Backlink</BootstrapModal.Title>
        </BootstrapModal.Header>
        <OverlayLoader visible={state.loader} />
        <BootstrapModal.Body>
          {state && state.status ?
            <form id="itemform" onSubmit={handleSubmit(onSubmitHandler)}>

              <Controller
                name={`project`}
                control={control}

                render={({ field }) => (

                  <UiProjectSelect
                    {...field}
                    required={true}

                    fetch_onload
                    className=""
                    label="Project"
                    message={errors.project?.message}


                  />

                )}
              />


              <div className="row">
                <div className="col-6">
                  <label className="form-label">Domain <span class="text-red font-bold text-lg">*</span></label>
                  <Controller

                    name="domain"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (

                      <AutoComplete
                        options={options}
                        style={{
                          width: '100%',
                          
                        }}
                        onBlur={(e) => validateDomain(e.target.value)}
                        onChange={onChange}
                        onSearch={(text) => setOptions(getPanelValue(text))}
                        default_val={value}
                        value={value}
                        name={name}
                        ref={(e) => {
                          ref(e)
                        }}
                      />


                    )}
                  />
                  {errors.domain && <div class="invalid-feedback d-block">Enter domain</div>}
                </div>
                <div className="col-6">
                  <Controller

                    name="date"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker

                        required={true}
                        label="Date"
                        onChange={onChange}
                        default_val={value}
                        message={errors.date?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={(e) => {
                          ref(e)
                        }}
                        name={name}
                      />
                    )}
                  />
                </div>
              </div>


              <UiInput
                label="Source URL"
                type="text"
                name="source_url"
                required
                {...register("source_url")}
                message={errors.source_url?.message}
              />

              <div className="row">
                <div className="col-6">
                  <UiSelect
                    label='Source'
                    required
                    name="source"
                    message={errors.source?.message}
                    options={state.sources}
                    {...register(`source`)}
                  />
                </div>
                <div className="col-3">
                  <UiInput
                    label="DA"
                    type="text"
                    name="da"
                    required
                    {...register("da")}
                    message={errors.da?.message}
                  />
                </div>
                <div className="col-3">
                  <UiInput
                    label="PA"
                    type="text"
                    name="pa"
                    required
                    {...register("pa")}
                    message={errors.pa?.message}
                  />
                </div>
              </div>


              <UiInput
                label="Target URL"
                type="text"
                name="target_url"
                required
                {...register("target_url")}
                message={errors.target_url?.message}
              />


              <div className="row">

                <div className="col">
                  <UiInput
                    label="Anchor Text"
                    type="text"
                    name="anchor_text"
                    {...register("anchor_text")}
                    message={errors.anchor_text?.message}
                  />
                </div>
                <div className="col">
                  <UiInput
                    label="Focused Keyword"
                    type="text"
                    name="focused_keyword"
                    {...register("focused_keyword")}
                    message={errors.focused_keyword?.message}
                  />
                </div>
              </div>


              <div className="row">

                <div className="col">
                  <UiSelect
                    label='BL Status'
                    required
                    name="bl_status"
                    message={errors.bl_status?.message}
                    options={["Sourced", "Attempt", "Live", "Loss"]}
                    {...register(`bl_status`)}
                  />
                </div>


                <div className="col">
                  <UiSelect
                    label='Type of Backlink'
                    name="bl_type"
                    message={errors.bl_type?.message}
                    options={["Do Follow", "No Follow"]}
                    {...register(`bl_type`)}
                  />
                </div>
              </div>



              {watchStatus === "Live" &&

                <div className="row">
                  <div className="col">
                    <UiInput
                      label="Live Link"
                      type="text"
                      name="live_link"
                      required
                      {...register("live_link")}
                      message={errors.live_link?.message}
                    />
                  </div>
                  <div className="col">
                    <Controller

                      name="live_date"
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDatePicker

                          required={true}
                          label="Date of Live Link"
                          onChange={onChange}
                          default_val={value}
                          message={errors.live_date?.message}
                          dateFormat="dd-MM-yyyy"
                          ref={(e) => {
                            ref(e)
                          }}
                          name={name}
                        />
                      )}
                    />
                  </div>

                </div>

              }
              {watchStatus === "Loss" &&
                <div className="row">

                  <div className="col">
                    <Controller

                      name="lost_date"
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDatePicker

                          required={true}
                          label="Date of Lost Link"
                          onChange={onChange}
                          default_val={value}
                          message={errors.lost_date?.message}
                          dateFormat="dd-MM-yyyy"
                          ref={(e) => {
                            ref(e)
                          }}
                          name={name}
                        />
                      )}
                    />
                  </div>

                </div>
              }



              <UiInput
                label="Remark"
                type="text"
                name="remark"
                {...register("remark")}
                message={errors.remark?.message}
              />





              {id && (<input type="hidden" value={id} name="id" {...register("id")} />)}
              <UiButton className="btn btn-primary" type="submit" title="Save" />

            </form>
            : <BgLoader />}
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default Backlink;
