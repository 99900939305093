import React, { useState, useEffect, useRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiDatePicker, UiDateRange, titleCase, OverlayLoader, UiActionDropDown, UiInput, UiSelect, Avatar, PageError, UiUserSelect, BgLoader, UiSourceSelect } from "../../ui";
import { useForm, useFieldArray, get, Controller } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom"
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { CSVLink, CSVDownload } from "react-csv";
import { FaExternalLinkAlt } from "react-icons/fa";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { toast } from "react-toastify";
import { MdOutlineDragHandle } from 'react-icons/md';
import { getStorage, setStorage, updateFilterUrl, rupees } from "../../helper.js";
import { Dropdown, Popconfirm, Menu } from 'antd';
import {
  Chart, ArcElement, CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  LineElement,
  Title, Tooltip as Tooltip1, Legend
} from 'chart.js';
import { Table } from 'antd';
import { Doughnut, Line } from 'react-chartjs-2';
import { useQuery, useQueryClient, select } from "react-query";
import { MdOutlineMoreVert } from 'react-icons/md'
import { IoMdClose, IoMdSearch } from "react-icons/io";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { set } from "date-fns";

const SortableContainer = sortableContainer(({ children }) => {
  return <div className="">{children}</div>;
});

const DragHandle = sortableHandle(() => (
  <span className="h2 pe-2 mb-0 text-secondary">
    <MdOutlineDragHandle />
  </span>
));

const SortableItem = sortableElement((value) => {
  return (
    <div className="d-flex pitable bg-white" style={{ minWidth: '700px' }} {...value} />
  );
});



export default function ProjectDashboard() {
  let { id } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const [loader, showLoader] = useState(false);
  const parsed = queryString.parse(location.search);
  const [state, setState] = useState({ count: 0 });
  const [tableFilter, setTablefilter] = useState('');



  const fetchData = () => {
    instance
      .get("/view_project?id=" + id)
      .then(function (response) {
        setState(response.data.item)

      })
      .catch(function (error) { })
      .then(function () { });
  }





  const handleDelete = (id) => {
    var tid = id.toString();

    showLoader(true)
    instance
      .get("/delete_project?id=" + tid)
      .then(function (response) {
        showLoader(false)
        if (response.data.status == 'success') {
          useStore.setState({ load_projects: false });
          navigate(-1, { replace: true });
          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }
      })
  }

  useEffect(() => {
    fetchData();
  }, [location]);

  const accountModal = useModal('project');

  const addAccount = (id) => {
    accountModal.show({ id: id }).then((res) => {
      useStore.setState({ load_projects: false });
      fetchData();
    });
  }




  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        <title>
          Project Dashboard
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title={state.name} >
            {state.can_edit == 1 ?
              <UiButton onClick={() => addAccount(id)} className="btn btn-outline-primary btn-sm me-2" title="Edit" ></UiButton>
              : ''}
            {state.can_delete == 1 ?
              <Popconfirm
                title="Delete?"
                description="Are you sure to delete this?"
                onConfirm={() => handleDelete(id)}
                okText="Yes"
                cancelText="No"
              >
                <button className="btn btn-outline-danger btn-sm" >Delete</button>
              </Popconfirm>
              : ''}






          </PageHeader>

          <OverlayLoader visible={loader} />
          {state.name ?
            <>
              <div className="card card-default position-relative mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <p>Start Date: {state.start_date}</p>
                      <p>URL: <a href={state.url}>{state.url}</a></p>
                      <p>Monthly Live Link Goal: {state.monthly_link_goal}</p>
                      <div className="p-3 bg-success bg-opacity-10 rounded d-inline-block">Current DA: <b>{state.current_da}</b></div>
                    </div>
                    <div className="col-sm-6">
                      <div className="p-4 bg-light rounded">
                        <h4>Initial Data</h4>
                        <div className="row">
                          <div className="col">
                            <div className="p-2 bg-white rounded text-center">
                              <h4 className="text-primary">{state.initial_da}</h4>
                              <span>DA</span>
                            </div>
                          </div>

                          <div className="col">
                            <div className="p-2 bg-white rounded text-center">
                              <h4 className="text-warning">{state.initial_ss}</h4>
                              <span>Spam Score</span>
                            </div>
                          </div>

                          <div className="col">
                            <div className="p-2 bg-white rounded text-center">
                              <h4 className="text-success">{state.initial_bl}</h4>
                              <span>Backlinks</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <ImportantData id={id} tableFilter={setTablefilter} />
              <BacklinksTable id={id} prefilter={tableFilter} />
            </>
            : <BgLoader />
          }
        </div>
      </div>
    </>
  );
}



export function ImportantData(props) {
  const { id, tableFilter } = props;
  const [state, setState] = useState(false);
  const [period, setPeriod] = useState('All Time');

  //Chart.register(ArcElement)
  Chart.register(CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title, ArcElement, Tooltip1, Legend);
  
  const task_donut_opt = {

    circumference: 360,
    cutout: '80%',
    legend: {
      display: true,
    },
   

  }


  const fetchData = () => {
    instance
      .get("/project_matrics?id=" + id + "&period=" + period)
      .then(function (response) {
        setState(response.data)

      })
      .catch(function (error) { })
      .then(function () { });
  }

  useEffect(() => {
    fetchData();
  }, [period]);
  return (

    <>

      <div className="card card-default position-relative mb-4">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="fs-4 fw-bold">Insights</div>
            <div style={{ minWidth: '240px' }}>
              <UiDateRange
                parentClass="mb-0"
                onUpdate={(e) => setPeriod(e)}
                value={period}

                name='date'
              />
            </div>

          </div>
          <div className="row mb-4">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-sm-4">
                  <div className="p-3 border rounded text-center bg-primary bg-opacity-10 h-pointer" onClick={() => tableFilter('?date=' + period)}>
                    <div className="fs-2 fw-bold text-primary">{state.all_bl ? state.all_bl : 0}</div>
                    <p className="mb-0">All Backlinks</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="p-3 border rounded text-center bg-success bg-opacity-10 h-pointer" onClick={() => tableFilter('?status=Live&date=' + period)}>
                    <div className="fs-2 fw-bold text-success">{state.live_bl ? state.live_bl : 0}</div>
                    <p className="mb-0">Live Backlinks</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="p-3 border rounded text-center bg-danger bg-opacity-10 h-pointer" onClick={() => tableFilter('?status=Loss&date=' + period)}>
                    <div className="fs-2 fw-bold text-danger">{state.lost_bl ? state.lost_bl : 0}</div>
                    <p className="mb-0">Lost Backlinks</p>
                  </div>
                </div>
              </div>
              <div className="p-3 border rounded text-center bg-light bg-opacity-10 h-pointer d-flex align-items-center mt-3 justify-content-center" onClick={() => tableFilter('?status=Live&live_date=' + period)}>
                <div className="fs-2 fw-bold me-3">{state.live_in_period ? state.live_in_period : 0}</div>
                <p className="mb-0">Backlinks turned Live in this period</p>
              </div>





            </div>
            <div className="col-lg-4">
              <h6>User Wise</h6>

              {state.user_wise ?
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>All</th>
                      <th>Live</th>

                    </tr>
                  </thead>
                  <tbody>
                    {state.user_wise.map((val, i) => (
                      <tr key={i}>
                        <td><span className="h-pointer text-primary" onClick={() => tableFilter('?user=' + val.id + '&date=' + period)}>{val.name}</span></td>
                        <td>{val.total}</td>
                        <td>{val.live_bl}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                : null
              }

            </div>
            <div className="col-lg-2">
              {state.source_wise ?
                <>
                  <h6>Source Wise</h6>
                  <Doughnut data={state.source_wise} options={task_donut_opt} />
                </>
                : null}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}




export function BacklinksTable(props) {
  const { id, prefilter } = props;
  const [state, setState] = useState(false);
  const [period, setPeriod] = useState('All Time');
  const [initialLoad, setInitLoad] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagenum, setPage] = useState(1);
  const [sort_by, setSortby] = useState('');
  const [sort_order, setSortorder] = useState('');
  const [search_params, setSearchParams] = useState('');
  const [filter, setFilter] = useState({});
  const queryClient = useQueryClient()

  const pagetype = "journal";

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: getStorage('perpage') ? [20, 50, 100, 500].includes(parseInt(getStorage('perpage'))) ? parseInt(getStorage('perpage')) : 20 : 20,
      showSizeChanger: true,
      pageSizeOptions: [20, 50, 100, 500],
    },

  });
  const clearSelection = () => {
    setSelectedRowKeys([])
  }


  const defaultValues = {
    date: 'All Time',
    status: null,
    type: null,
    user: null,
    source: null,
  };

  const schema = yup.object().shape(
    {

    }
  );


  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmitHandler = async (data) => {

    setSelectedRowKeys([])
    setPage(1)
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value : val
      }
    })

    setSearchParams('?perpage=' + tableParams.pagination.pageSize + '&' + new URLSearchParams(obj).toString())


  };


  const clearFilter = () => {
    setSelectedRowKeys([])
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
    reset(defaultValues)
    setSearchParams('?perpage=' + tableParams.pagination.pageSize)
  }





  const headers = [
    { label: "Sr. No.", key: "sno" },
    { label: "Date", key: "date" },
    { label: "Domain", key: "domain" },
    { label: "Project", key: "project" },
    { label: "DA", key: "da" },
    { label: "PA", key: "pa" },
    { label: "Source", key: "source" },
    { label: "Source URL", key: "source_url" },
    { label: "Target URL", key: "target_url" },
    { label: "Status", key: "bl_status" },
    { label: "Type", key: "bl_type" },
    { label: "Live Link", key: "live_link" },
    { label: "Anchor Text", key: "anchor_text" },
    { label: "Focused Keyword", key: "focused_keyword" },
    { label: "Live Date", key: "live_date" },
    { label: "Lost Date", key: "lost_date" },
    { label: "User", key: "user" },
    { label: "Remark", key: "remark" },



  ];



  const columns = [
    {
      title: 'No.',
      width: '40px',
      dataIndex: 'sno',
    },
    {
      title: 'Date',
      width: '70px',
      dataIndex: 'date',
      sorter: 'date',
    },
    {
      title: 'Domain',
      width: '100px',
      dataIndex: 'domain',
      sorter: 'domain',
      render: (text, row) => (
        <div>
          <p className="mb-0">{row.domain}</p>
          {row.bl_status == 'Live' ? <span className="text-success small me-2">Live on {row.live_date}</span> : row.bl_status == 'Loss' ? <span className="text-danger small me-2">Lost on {row.lost_date}</span> : null}
          {row.live_link ? <a href={row.live_link} className="small" ><FaExternalLinkAlt /> Live link</a> : null}
        </div>
      ),
    },
    {
      title: 'Project',
      width: '100px',
      dataIndex: 'project',
      sorter: 'project',
    },
    {
      title: 'BL Type',
      width: '60px',
      dataIndex: 'bl_type',
    },
    {
      title: 'DA',
      width: '40px',
      dataIndex: 'da',
      sorter: 'da',
    },
    {
      title: 'PA',
      width: '40px',
      dataIndex: 'pa',
      sorter: 'pa',
    },
    {
      title: 'Source',
      width: '80px',
      dataIndex: 'source',
      sorter: 'source',
    },
    {
      title: 'Status',
      width: '60px',
      dataIndex: 'bl_status',
      sorter: 'bl_status',
      render: (text, row) => (
        <span className={`badge status-${row.bl_status.toLowerCase()}`}>{row.bl_status}</span>
      )
    },
    {
      title: 'User',
      width: '100px',
      dataIndex: 'user',
      sorter: 'user',
    },
    {
      title: 'Remark',
      width: '100px',
      dataIndex: 'remark',
    },





    {
      title: '',
      fixed: 'right',
      width: '40px',
      render: (text, row) => (
        <div className="tableaction1">
          <Dropdown menu={{
            items: items(row),
          }}
            trigger={['click']}
          >
            <button className='btn btn-light'><MdOutlineMoreVert /></button>


          </Dropdown>

        </div>


      )
    },

  ];


  useEffect(() => {


    const defaultValues1 = {
      search: filter.search,
      source: filter.source,
      status: filter.status,
      type: filter.type,
      user: filter.user ? parseInt(filter.user) : null,
      date: filter.date,


    };
    reset(defaultValues1)

  }, [filter]);


  async function fetchData(filter) {
    let currentUrlParams = new URLSearchParams(filter);
    currentUrlParams.set('project', id);
    const { data } = await instance.get(
      "/backlinks?" + currentUrlParams
    );

    var dataa = data;
    return dataa;
  }

  const { data, error, isError, isLoading, isFetching } = useQuery([pagetype, search_params], () => fetchData(search_params), {
    keepPreviousData: true,
    staleTime: 6,
    //enabled: initialLoad
  });



  const handleDelete = (id) => {
    var tid = id.toString();

    setTableDisable(true)
    instance
      .get("/delete_backlink?id=" + tid)
      .then(function (response) {
        setTableDisable(false)
        if (response.data.status == 'success') {
          queryClient.invalidateQueries([pagetype])
          clearSelection()
          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }
      })
  }

  const blModal = useModal('backlink');

  const addBacklink = (row, project) => {
    blModal.show({ id: row, project: project }).then((res) => {
      queryClient.invalidateQueries([pagetype])
    });
  }
  const items = (e) => [
    {
      key: '1',
      label: <span>Edit</span>,
      disabled: e.can_edit == 1 ? false : true,
      onClick: () => addBacklink(e.id),
    },
    {
      key: '2',
      disabled: e.can_delete == 1 ? false : true,
      label: (
        <Popconfirm
          title="Delete?"
          description="Are you sure to delete this?"
          onConfirm={() => handleDelete(e.id)}
          okText="Yes"
          cancelText="No"
        >
          <span>Delete</span>
        </Popconfirm>
      ),
    },


  ];



  const handleTableChange = (pagination, filters, sorter) => {
    var direction = ''

    if (sorter.order == 'ascend') {
      direction = 'asc';
    } else if (sorter.order == 'descend') {
      direction = 'desc';
    }
    var pg = pagination.current
    setPage(pg)
    let currentUrlParams = new URLSearchParams(search_params);

    if (sorter.order) {
      currentUrlParams.set('sort_by', sorter.field);
      currentUrlParams.set('sort', direction);
    } else {
      currentUrlParams.delete('sort_by');
      currentUrlParams.delete('sort');
    }

    currentUrlParams.set('page', pg);
    var perpage = pagination.pageSize
    currentUrlParams.set('perpage', perpage);
    setStorage('perpage', perpage)

    //console.log(currentUrlParams.toString())
    setSearchParams('?' + currentUrlParams.toString())


    setTableParams({
      pagination,
      filters,
      ...sorter,
    });


  };


  useEffect(() => {

    console.log(prefilter)
    const parsed = queryString.parse(prefilter);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: parseInt(parsed.page),
        },
      });
    } else {
      // setPage(1)
    }

    setFilter(parsed)
    setSearchParams(prefilter)



  }, [prefilter]);


  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  if (!data) {
    return (
      <BgLoader />
    )
  }

  return (

    <>

      <div className="card card-default position-relative mb-4">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="fs-4 fw-bold">Backlinks</div>
            <div >
            <UiButton title="Add Backlink" className="btn btn-primary" onClick={() => addBacklink('new', id)} />
              {data.items &&
                <>
                  <CSVLink data={data.items} filename={"Backlinks.csv"} headers={headers} className={`btn btn-outline-primary d-inline-flex mx-2 ${data.items.length == 0 && 'disabled'}`} >Export</CSVLink>
                </>
              }
            </div>

          </div>
          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <div className="row">
              <div className="col-lg-3">
                <label className="form-label">Date</label>
                <Controller
                  name="date"
                  control={control}
                  defaultValue="All Time"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDateRange

                      onUpdate={onChange}
                      value={value}
                      ref={ref}
                      name={name}

                    />
                  )}
                />
              </div>
              <div className="col-lg-2">

                <UiSelect
                  label='Status'
                  name="status"
                  options={["Sourced", "Attempt", "Live", "Loss"]}
                  {...register(`status`)}
                />
              </div>
              <div className="col-lg-2">

                <UiSelect
                  label='Type of Backlink'
                  name="type"
                  options={["Do Follow", "No Follow"]}
                  {...register(`type`)}
                />
              </div>
              <div className="col-lg-2">
                <Controller
                  name={`user`}
                  control={control}
                  render={({ field }) => (
                    <UiUserSelect
                      {...field}
                      className=""
                      fetch_onload
                      label="User"
                    />

                  )}
                />
              </div>

              <div className="col-lg-2">
                <Controller
                  name={`source`}
                  control={control}
                  render={({ field }) => (
                    <UiSourceSelect
                      {...field}
                      className=""
                      fetch_onload
                      label="Source"
                    />

                  )}
                />
              </div>
              <div className="col-lg-1" style={{ paddingTop: '26px' }}>
                <div className="d-flex justify-content-end">

                  <UiButton type="" className="ms-2 btn-light" title={<IoMdClose />} onClick={() => clearFilter()} />
                  <UiButton type="submit" className="ms-2 btn-primary" title={<IoMdSearch />} />
                </div>

              </div>
            </div>
          </form>


          <div style={{ height: '34px' }} className="ps-3">
            {selectedRowKeys && selectedRowKeys.length > 0 && (
              <div className="bulkaction">
                <div>

                  <Popconfirm
                    title="Delete?"
                    description="Are you sure to delete this?"
                    onConfirm={() => handleDelete(selectedRowKeys)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button className="btn btn-outline-danger btn-sm me-2" disabled={data.access.delete ? false : true}  >Delete</button>
                  </Popconfirm>
                  <button className="btn btn-light btn-sm me-2" onClick={() => clearSelection()}>Clear Selection <div className="badge bg-primary">{selectedRowKeys.length}</div></button>

                </div>
              </div>
            )}
          </div>

          <Table
            id="report"
            locale={{
              emptyText: (<TableZero title="Backlinks" />)
            }}

            size="small"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data.items}
            pagination={{ ...tableParams.pagination, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
            loading={{ spinning: (tableDisable || isFetching) }}
            rowSelection={rowSelection}
            onChange={handleTableChange}
            rowClassName={(sa) => sa.bl_status == 'Live' && 'bg-success bg-opacity-10'}
            scroll={{
              x: 960,

            }}

          />
        </div>
      </div>
    </>
  )
}