import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";


import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Select, { StylesConfig } from "react-select";

const ImportBacklinks = NiceModal.create(
  ({  }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      file: yup.mixed().test("file", "You need to provide a file", (value) => {
        if (value.length > 0) {  
          return true;
        }
        return false;
        }),

    });
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag();
      //reset();
    };
    var SaveTag = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#importfform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/import_csv.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true, name: response.data.name });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };



    let navigate = useNavigate();

    const handleHide = () => {
      modal.hide();

    }

    const downloadCSVTemp = () => {
      const url = "https://api1.upmarkcrm.in/import-backlinks.csv"
          const name = 'template.csv'
          fetch(url)
            .then(response1 => response1.blob())
            .then(blob => {
              const blobURL = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = blobURL;
              a.style = "display: none";

              if (name && name.length) a.download = name;
              document.body.appendChild(a);
              a.click();
            })
    }
    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Import Backlinks</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          <a className="mb-3" onClick={()=>downloadCSVTemp()}>Download Template</a>
          <form id="importfform" onSubmit={handleSubmit(onSubmitHandler)}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Select File (CSV format)</Form.Label>
              <Form.Control name="file" accept=".csv" type="file" {...register("file")}/>
              <Form.Text className="text-danger">
                    {errors.file?.message}
                  </Form.Text>
            </Form.Group>
         
            <Button variant="primary" type="submit">
              Import
            </Button>
          </form>
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default ImportBacklinks;
