import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiProjectSelect, UiUserSelect, BgLoader, InLoader, UiSourceSelect, UiDateRange, UiInput, UiSelect, Avatar, PageError, Toastcontent } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Link, useLocation } from "react-router-dom"
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import { toast } from "react-toastify";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import { Table } from 'antd';
import { getStorage, setStorage, updateFilterUrl, rupees } from "../../helper.js";
import { Dropdown, Popconfirm, Menu } from 'antd';
import { MdOutlineMoreVert } from "react-icons/md";
import { render } from "react-dom";

export default function Backlinks() {
  let navigate = useNavigate();
  const [fy] = useOutletContext();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);
  const [initialLoad, setInitLoad] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);


  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: getStorage('perpage') ? [20, 50, 100, 500].includes(parseInt(getStorage('perpage'))) ? parseInt(getStorage('perpage')) : 20 : 20,
      showSizeChanger: true,
      pageSizeOptions: [20, 50, 100, 500],
    },

  });


  const schema = yup.object().shape(
    {

    }
  );


  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmitHandler = async (data) => {

    setSelectedRowKeys([])
    setPage(1)
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value : val
      }
    })


    navigate(updateFilterUrl(obj, 'backlinks', 1), { replace: location.search ? true : false })


  };

  const importModal = useModal('import-backlinks');
  const importcModal = () => {
    importModal.show({ }).then((res) => {
      clearFilter()
      queryClient.invalidateQueries(['backlinks'])
    });
  }

  const handleDelete = (id) => {
    var tid = id.toString();

    setTableDisable(true)
    instance
      .get("/delete_backlink?id=" + tid)
      .then(function (response) {
        setTableDisable(false)
        if (response.data.status == 'success') {
          queryClient.invalidateQueries(['backlinks'])
          clearSelection()
          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }
      })
  }





  const items = (e) => [
    {
      key: '1',
      label: <span>Edit</span>,
      disabled: e.can_edit == 1 ? false : true,
      onClick: () => addBacklink(e.id),
    },
    {
      key: '2',
      disabled: e.can_delete == 1 ? false : true,
      label: (
        <Popconfirm
          title="Delete?"
          description="Are you sure to delete this?"
          onConfirm={() => handleDelete(e.id)}
          okText="Yes"
          cancelText="No"
        >
          <span>Delete</span>
        </Popconfirm>
      ),
    },


  ];

  const headers = [
    { label: "Sr. No.", key: "sno" },
    { label: "Date", key: "date" },
    { label: "Domain", key: "domain" },
    { label: "Project", key: "project" },
    { label: "DA", key: "da" },
    { label: "PA", key: "pa" },
    { label: "Source", key: "source" },
    { label: "Source URL", key: "source_url" },
    { label: "Target URL", key: "target_url" },
    { label: "Status", key: "bl_status" },
    { label: "Type", key: "bl_type" },
    { label: "Live Link", key: "live_link" },
    { label: "Anchor Text", key: "anchor_text" },
    { label: "Focused Keyword", key: "focused_keyword" },
    { label: "Live Date", key: "live_date" },
    { label: "Lost Date", key: "lost_date" },
    { label: "User", key: "user" },
    { label: "Remark", key: "remark" },
    
  

  ];

  const [filter, setFilter] = useState({});



  async function fetchData(filter) {

    const { data } = await instance.get(
      "/backlinks" + filter
    );

    var dataa = data;
    return dataa;
  }

  const defaultValues = {
    search: null,

  };

  const clearFilter = () => {
    setSelectedRowKeys([])
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
    reset(defaultValues)
    navigate(updateFilterUrl({}, 'backlinks', 1), { replace: true })
  }


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);

  const blModal = useModal('backlink');

  const addBacklink = (row) => {
    blModal.show({ id: row }).then((res) => {
      queryClient.invalidateQueries(['backlinks'])
    });
  }

  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: parseInt(parsed.page),
        },
      });
    } else {
      // setPage(1)
    }

    setFilter(parsed)




  }, []);


  const columns = [
    {
      title: 'No.',
      width: '40px',
      dataIndex: 'sno',
    },
    {
      title: 'Date',
      width: '70px',
      dataIndex: 'date',
      sorter: 'date',
      sortOrder: parsed.sort_by == 'date' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'date' && parsed.sort == 'desc' ? 'descend' : '',
    },
    {
      title: 'Domain',
      width: '100px',
      dataIndex: 'domain',
      sorter: 'domain',
      sortOrder: parsed.sort_by == 'domain' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'domain' && parsed.sort == 'desc' ? 'descend' : '',
      render: (text, row) => (
        <div>
          <p className="mb-0">{row.domain}</p>
          {row.bl_status == 'Live' ? <span className="text-success small me-2">Live on {row.live_date}</span> : row.bl_status == 'Loss' ? <span className="text-danger small me-2">Lost on {row.lost_date}</span> : null}
          {row.live_link ? <a href={row.live_link} className="small" ><FaExternalLinkAlt /> Live link</a> : null}
        </div>
      ),
    },
    {
      title: 'Project',
      width: '100px',
      dataIndex: 'project',
      sorter: 'project',
      sortOrder: parsed.sort_by == 'project' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'project' && parsed.sort == 'desc' ? 'descend' : '',
    },
    {
      title: 'BL Type',
      width: '60px',
      dataIndex: 'bl_type',
    },
    {
      title: 'DA',
      width: '40px',
      dataIndex: 'da',
      sorter: 'da',
      sortOrder: parsed.sort_by == 'da' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'da' && parsed.sort == 'desc' ? 'descend' : '',
    },
    {
      title: 'PA',
      width: '40px',
      dataIndex: 'pa',
      sorter: 'pa',
      sortOrder: parsed.sort_by == 'pa' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'pa' && parsed.sort == 'desc' ? 'descend' : '',
    },
    {
      title: 'Source',
      width: '80px',
      dataIndex: 'source',
      sorter: 'source',
      sortOrder: parsed.sort_by == 'source' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'source' && parsed.sort == 'desc' ? 'descend' : '',
    },
    {
      title: 'Status',
      width: '60px',
      dataIndex: 'bl_status',
      sorter: 'bl_status',
      sortOrder: parsed.sort_by == 'bl_status' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'bl_status' && parsed.sort == 'desc' ? 'descend' : '',
      render: (text, row) => (
        <span className={`badge status-${row.bl_status.toLowerCase()}`}>{row.bl_status}</span>
      )
    },
    {
      title: 'User',
      width: '100px',
      dataIndex: 'user',
      sorter: 'user',
      sortOrder: parsed.sort_by == 'user' && parsed.sort == 'asc' ? 'ascend' : parsed.sort_by == 'user' && parsed.sort == 'desc' ? 'descend' : '',
    },
    {
      title: 'Remark',
      width: '100px',
      dataIndex: 'remark',
    },





    {
      title: '',
      fixed: 'right',
      width: '40px',
      render: (text, row) => (
        <div className="tableaction1">
          <Dropdown menu={{
            items: items(row),
          }}
            trigger={['click']}
          >
            <button className='btn btn-light'><MdOutlineMoreVert /></button>


          </Dropdown>

        </div>


      )
    },

  ];


  useEffect(() => {

    var set_perpage = 0;
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.perpage) {
        if ([20, 50, 100, 500].includes(parseInt(parsed.perpage))) {
          setInitLoad(true)
        } else {
          setStorage('perpage', 20)
          set_perpage = 1;
        }

      } else {
        set_perpage = 1;
      }

    } else {
      set_perpage = 1;
    }

    if (set_perpage == 1) {


      navigate(updateFilterUrl(window.location.search, 'backlinks', 1), { replace: true })
    }

  }, [location.search]);

  const { data, error, isError, isLoading, isFetching } = useQuery(["backlinks", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
    enabled: initialLoad
  });


  const clearSelection = () => {
    setSelectedRowKeys([])
  }
  useEffect(() => {

    if (zstate) {
      const defaultValues1 = {
        search: filter.search,

      };
      reset(defaultValues1)
    }
  }, [filter]);


  useEffect(() => {
    if (data) {
      const parsed = queryString.parse(location.search);
      var setpage = 1;
      if (parsed.page) {
        setpage = parseInt(parsed.page);
      }
      if (parsed.page && parseInt(parsed.page) > 1 && data.items.length == 0) {
        let currentUrlParams = new URLSearchParams(window.location.search);

        setpage = Math.ceil(data.total / parseInt(tableParams.pagination.pageSize));
        setPage(setpage)

        currentUrlParams.set('page', setpage);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.total,
            current: setpage,
          },
        });

        navigate('/backlinks?' + currentUrlParams.toString(), { replace: true })
      } else {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.total,
            current: setpage,
          },
        });
      }
    }

  }, [data]);

  const handleTableChange = (pagination, filters, sorter) => {
    var direction = ''

    if (sorter.order == 'ascend') {
      direction = 'asc';
    } else if (sorter.order == 'descend') {
      direction = 'desc';
    }
    var pg = pagination.current
    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);

    if (sorter.order) {
      currentUrlParams.set('sort_by', sorter.field);
      currentUrlParams.set('sort', direction);
    } else {
      currentUrlParams.delete('sort_by');
      currentUrlParams.delete('sort');
    }

    currentUrlParams.set('page', pg);
    var perpage = pagination.pageSize
    currentUrlParams.set('perpage', perpage);
    setStorage('perpage', perpage)
    if (location.search) {
      navigate('/backlinks?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/backlinks?' + currentUrlParams.toString())
    }

    setTableParams({
      pagination,
      filters,
      ...sorter,
    });


  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  if (isLoading) {
    return <BgLoader />;
  }
  if (!data) {
    return (
      <BgLoader />
    )
  }

  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Backlinks
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Backlinks" >

            {data.items &&
              <>
                <CSVLink data={data.items} filename={"Backlinks.csv"} headers={headers} className={`btn btn-outline-primary d-inline-flex mx-2 ${data.items.length == 0 && 'disabled'}`} >Export</CSVLink>
              </>
            }
             <button className="btn btn-primary me-2" onClick={() => importcModal()}>Import</button>
            <UiButton className="btn btn-primary" onClick={() => addBacklink('new')} title="Add" icon="add" disabled={data.access.add == 0 ? true : false}></UiButton>


          </PageHeader>

          <div className="position-relative bg-white rounded mb-4">

            <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
              <div className="p-3 pb-0">

                <div className="row">

                  <div className="col-md-3">
                    <UiInput
                      label="Search"
                      type="text"

                      name="search"

                      {...register("search")}
                    />
                  </div>




                  <div className="col-md-3">

                    <label className="form-label">Date</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange
                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>


                  <div className="col-md-2">

                    <Controller
                      name={`user`}
                      control={control}
                      render={({ field }) => (
                        <UiUserSelect
                          {...field}
                          className=""
                          fetch_onload
                          label="User"
                        />

                      )}
                    />
                  </div>

                  <div className="col-md-2">
                    <Controller
                      name={`source`}
                      control={control}
                      render={({ field }) => (
                        <UiSourceSelect
                          {...field}
                          className=""
                          fetch_onload
                          label="Source"
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-2">

                    <UiSelect
                      label='Status'
                      name="status"
                      options={["Sourced", "Attempt", "Live", "Loss"]}
                      {...register(`status`)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">

                    <UiSelect
                      label='Type of Backlink'
                      name="type"
                      options={["Do Follow", "No Follow"]}
                      {...register(`type`)}
                    />
                  </div>


                  <div className="col-md-3">

                    <label className="form-label">Live Date</label>
                    <Controller
                      name="live_date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange
                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>

                  <div className="col-md-3">

                    <label className="form-label">Lost Date</label>
                    <Controller
                      name="lost_date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange
                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>
                  <div className="col">

                    <Controller
                      name={`project`}
                      control={control}

                      render={({ field }) => (

                        <UiProjectSelect
                          {...field}
                          fetch_onload
                          className=""
                          label="Project"
                        />

                      )}
                    />

                  </div>









                  <div className="col" style={{ paddingTop: '28px', minWidth: '125px' }}>
                    <div className="d-flex justify-content-end">

                      <UiButton type="" className="ms-2 btn-light" title={<IoMdClose />} onClick={() => clearFilter()} />
                      <UiButton type="submit" className="ms-2 btn-primary" title="Filter" />
                    </div>

                  </div>
                </div>



              </div>
            </form>

            <div style={{ height: '34px' }} className="ps-3">
              {selectedRowKeys && selectedRowKeys.length > 0 && (
                <div className="bulkaction">
                  <div>

                    <Popconfirm
                      title="Delete?"
                      description="Are you sure to delete this?"
                      onConfirm={() => handleDelete(selectedRowKeys)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button className="btn btn-outline-danger btn-sm me-2" disabled={data.access.delete ? false : true}  >Delete</button>
                    </Popconfirm>
                    <button className="btn btn-light btn-sm me-2" onClick={() => clearSelection()}>Clear Selection <div className="badge bg-primary">{selectedRowKeys.length}</div></button>

                  </div>
                </div>
              )}
            </div>




            <Table
              locale={{
                emptyText: (<TableZero title="Backlinks" />)
              }}

              size="small"
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={data.items}
              pagination={{ ...tableParams.pagination, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
              loading={{ spinning: (tableDisable || isFetching) }}
              rowSelection={rowSelection}
              rowClassName={(sa) => sa.bl_status == 'Live' && 'bg-success bg-opacity-10'}
              onChange={handleTableChange}
              scroll={{
                x: 960,

              }}
            />
          </div>

        </div>
      </div>
    </>
  );
}
